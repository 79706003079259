<template>
  <v-dialog v-model="dialog" max-width="800">
    <v-card :loading="loading">
      <v-card-title>
        اطلاعات کاربر "{{
          selectedData.first_name + " " + selectedData.last_name || ""
        }}"
        <v-spacer />

        <v-btn outlined color="blue" @click="dialog = false">
          بازگشت
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-row v-if="selectedData.avatar_url" justify="center">
          <v-col cols="12" sm="6" md="4" lg="2">
            <v-img
              :src="selectedData.avatar_url"
              class="grey lighten-2"
              height="200px"
            >
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-col>
        </v-row>
        <v-row class="mt-4">
          <v-col cols="12" sm="6">
            <small>نام کاربر</small>
            <h3 class="black--text">
              {{ selectedData.first_name + " " + selectedData.last_name }}
            </h3>
          </v-col>

          <v-col cols="12" sm="6" v-if="selectedData.father_name">
            <small>نام پدر</small>
            <h3 class="black--text">{{ selectedData.father_name }}</h3>
          </v-col>

          <v-col cols="12" sm="6" v-if="selectedData.birthday">
            <small>تاریخ تولد</small>
            <h3 class="black--text">
              {{ selectedData.birthday | moment("jYYYY/jMM/jDD") }}
            </h3>
          </v-col>

          <v-col cols="12" sm="6" v-if="selectedData.national_code">
            <small>کد ملی</small>
            <h3 class="black--text">{{ selectedData.national_code }}</h3>
          </v-col>

          <v-col cols="12" sm="6" v-if="selectedData.cellphone">
            <small>شماره تلفن کاربر</small>
            <h3 class="black--text">{{ selectedData.cellphone }}</h3>
          </v-col>

          <v-col cols="12" sm="6" v-if="selectedData.email">
            <small>ایمیل</small>
            <h3 class="black--text">{{ selectedData.email }}</h3>
          </v-col>

          <v-col cols="12" sm="6" v-if="selectedData.residence">
            <small>شهر تولد</small>
            <h3 class="black--text">{{ selectedData.residence }}</h3>
          </v-col>

          <v-col cols="12" sm="6" v-if="selectedData.address">
            <small>آدرس</small>
            <h3 class="black--text">{{ selectedData.address }}</h3>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      loading: false,
    };
  },
  props: {
    selectedData: {
      required: true,
      type: Object,
    },
  },
};
</script>

<style></style>
