<template>
  <v-container fluid class="px-0">
    <MyDialog ref="itemsDialog" @onConfirm="deleteItem" />
    <users-modal ref="usersModal" :selectedData="selectedData" />
    <MyDataTable
      title="کاربر های موجود"
      subtitle="مدیریت کاربر"
      addtitle="ایجاد کاربر جدید"
      :addpageRoute="
        `${$route.path}/create${
          $route.query.page ? '?page=' + $route.query.page : ''
        }`
      "
      :headers="headers"
      :items="items"
      :loading="loading"
      :pagesLength="pagesLength"
      @onPageChanged="GetList"
      @onDeleteClick="deleteItem"
      @onEditClick="onEditClick"
      class="mt-n13 moholand-shadow"
    >
      <template #middleButtonTag="{ row }">
        <v-btn
          class="mx-2"
          dark
          small
          color="primary"
          @click="onDetailsClick(row)"
        >
          <v-icon small dark>mdi-apps</v-icon>
          مشاهده
        </v-btn>
      </template>
      <template #topContentTag>
        <v-row align="center" justify="center" class="mb-2 px-4">
          <v-col md="10" cols="12">
            <v-form-base
              :col="{ cols: 12, md: 6 }"
              :model="model"
              :schema="schema"
            />
          </v-col>
          <v-col cols="12" md>
            <v-btn color="primary" block @click="search">
              جستجو
            </v-btn>
          </v-col>
        </v-row>
      </template>
      <template #contentTag="{ row }">
        <td>{{ row.first_name + " " + row.last_name }}</td>
        <td>{{ row.cellphone || "-" }}</td>
        <td>
          {{
            row.roles.length > 0 && row.roles[0].name
              ? userTypes[row.roles[0].name]
              : "-"
          }}
        </td>
      </template>
    </MyDataTable>
  </v-container>
</template>

<script>
import { Constants } from "@/constants/GlobalConstants";
import MyAxios from "@/constants/MyAxios";
import MyDialog from "@/components/MyDialog";
import MyDataTable from "@/components/MyDataTable";
import UsersModal from "../../../../components/Panel/UsersModal.vue";
import VFormBase from "vuetify-form-base";

export default {
  created() {
    this.GetList();
  },
  components: {
    MyDialog,
    MyDataTable,
    UsersModal,
    VFormBase,
  },
  methods: {
    search() {
      this.$router.push({
        path: "/users",
        query: {
          role: this.model.role || undefined,
          first_name: this.model.first_name || undefined,
          last_name: this.model.last_name || undefined,
          cellphone: this.model.cellphone || undefined,
        },
      });
      this.GetList();
    },
    GetList() {
      if (!this.loading) this.loading = true;

      if (
        this.$route.query.role ||
        this.$route.query.first_name ||
        this.$route.query.last_name ||
        this.$route.query.cellphone
      )
        MyAxios.get("/users/admin/filter", {
          params: {
            page: this.$route.query?.page || 1,
            conditions: {
              first_name: this.$route.query.first_name || undefined,
              last_name: this.$route.query.last_name || undefined,
              cellphone: this.$route.query.cellphone || undefined,
              role: this.$route.query.role || undefined,
            },
          },
        })
          .then((response) => {
            this.loading = false;
            this.items = response.data.data;
            this.pagesLength = response.data.last_page;
          })
          .catch((error) => {
            this.loading = false;
          });
      else
        MyAxios.get("/users/index", {
          params: {
            page: this.$route.query?.page || 1,
            with: "roles",
          },
        })
          .then((response) => {
            this.loading = false;
            this.items = response.data.data;
            this.pagesLength = response.data.last_page;
          })
          .catch((error) => {
            this.loading = false;
          });
    },
    onEditClick(item) {
      this.$router.push({
        path: "/users/edit/" + item.id,
        query: this.$route.query || {},
      });
    },
    onDetailsClick(item) {
      this.selectedData = item;
      this.$refs.usersModal.dialog = true;
    },
    deleteItem(item) {
      MyAxios.delete(`/users/${item.id}/delete`)
        .then((response) => {
          console.log("delete", response);
          this.$root.$emit("toast", {
            text: "آیتم با موفقیت حذف شد.",
            type: "success",
          });
          this.GetList();
        })
        .catch((error) => {
          console.log("laws", error.response);
        });
    },
    updateItems(items) {
      this.items = items;
    },
    disableFields(key) {
      if (this.model[key] && this.model[key].length)
        Object.keys(this.model).forEach((newkey) => {
          if (newkey == key)
            this.schema[newkey] = {
              ...this.schema[newkey],
              disabled: false,
            };
          else
            this.schema[newkey] = {
              ...this.schema[newkey],
              disabled: true,
            };
        });
      else
        Object.keys(this.model).forEach((newkey) => {
          this.schema[newkey] = {
            ...this.schema[newkey],
            disabled: false,
          };
        });
    },
  },
  data() {
    return {
      loading: true,
      items: [],
      selectedData: {},
      page: 1,
      pagesLength: 1,
      path: "/users/index",
      pathParams: {
        with: "roles",
      },
      userTypes: {
        admin: "ادمین",
        vendor: "فروشنده",
        user: "موهولندی",
        instructor: "مدرس",
      },
      headers: [
        {
          text: "نام",
          align: "start",
          value: "name",
        },
        { text: "موبایل", value: "cellphone" },
        { text: "نقش", value: "cellphone" },
        { text: "عملیات", value: "actions", sortable: false },
      ],
      model: {
        role: this.$route.query.role || "",
        first_name: this.$route.query.first_name || "",
        last_name: this.$route.query.last_name || "",
        cellphone: this.$route.query.category || "",
      },
      schema: {
        role: {
          type: "CustomInput",
          label: "نقش فروشنده",
          inputType: "autocomplete",
          items: [
            { id: "admin", value: "مدیر کل" },
            { id: "user", value: "موهولندی" },
            { id: "vendor", value: "فروشنده" },
            { id: "instructor", value: "مدرس" },
          ],
          itemValue: "id",
          itemText: "value",
          clearable: true,
          disabled: false,
        },
        first_name: {
          type: "CustomInput",
          label: "نام",
          required: true,
          disabled: false,
        },
        last_name: {
          type: "CustomInput",
          label: "نام خانوادگی",
          required: true,
          disabled: false,
        },
        cellphone: {
          type: "CustomInput",
          label: "موبایل",
          required: true,
          disabled: false,
        },
      },
    };
  },
  watch: {
    "model.role": {
      immediate: true,
      handler() {
        this.disableFields("role");
      },
    },
    "model.first_name": {
      immediate: true,
      handler() {
        this.disableFields("first_name");
      },
    },
    "model.last_name": {
      immediate: true,
      handler() {
        this.disableFields("last_name");
      },
    },
    "model.cellphone": {
      immediate: true,
      handler() {
        this.disableFields("cellphone");
      },
    },
  },
};
</script>

<style></style>
